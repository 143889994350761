import * as React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import PageWrapper from "../../page-wrapper";
import Card from "../../card";
import Button from "../../button";
import siteConfig from "../../../../site-config.json";
import DollarImage from "../../../images/feature/accounts-receivables.svg";
import TruckImage from "../../../images/feature/virtual-assitant.svg";
import ReturnsImage from "../../../images/feature/eligibility-benefits.svg";

const SMALL_BREAKPOINT = "screen and (min-width: 500px)";
const MEDIUM_BREAKPOINT = "screen and (min-width: 900px)";
const LARGE_BREAKPOINT = "screen and (min-width: 1100px)";

const FeatureSectionWrapper = styled.section`
  background: var(--color-feature-background);
  padding: 48px 0;

  @media ${MEDIUM_BREAKPOINT} {
    padding-top: 1em;
    padding-bottom: 0.4em;
  }
`;

const FeatureCardList = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 81x) minmax(256px, min-content);
  grid-gap: 20px;
  margin: 0 0 16px;

  @media ${MEDIUM_BREAKPOINT} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: min-max(256px, min-content) 351px;
  }
`;

const FeatureCard = styled(Card).attrs({ as: "li" })`
  display: grid;
  align-items: center;
  grid-template-columns: 48px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  background-color: var(--color-feature-card);

  @media ${MEDIUM_BREAKPOINT} {
    display: block;
  }

  svg {
    grid-column: 1;
    max-width: 100%;
  }

  h3,
  p {
    grid-column: 2;
  }

  h3 {
    margin: 0;
    font: var(--text-font-h4);
    font-weight: 700;

    @media ${MEDIUM_BREAKPOINT} {
      margin: 0.5em 0 0.666667em;
    }
  }

  p {
    margin: 0;
    font: var(--text-font-h5);
    font-weight: 500;
    opacity: 0.7;

    .dark-mode & {
      opacity: 0.5;
    }
  }
`;

const CtaCard = styled(Card).attrs({ as: "li" })`
  background-color: var(--color-feature-card);
  padding: 0;
  overflow: hidden;
  max-width: 100%;
  position: relative;

  @media ${MEDIUM_BREAKPOINT} {
    grid-column: 1/4;
  }

  .content {
    padding: 24px;
    position: relative;
    z-index: 1;
    text-align: center;
    display: flex;
    flex-direction: column;

    @media ${SMALL_BREAKPOINT} {
      text-align: left;
      display: block;
    }

    @media ${MEDIUM_BREAKPOINT} {
      padding: 64px;
    }
  }

  p {
    margin: 0 0 32px;
    font: var(--text-font-h2);
    font-weight: 700;
    margin-bottom: 282px;

    @media ${SMALL_BREAKPOINT} {
      margin: 0 0 48px;
    }

    .dark-mode & {
      color: #fff2c9;
    }
  }
`;

const BgWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;

  @media ${SMALL_BREAKPOINT} {
    position: static;
    width: 100%;
    height: 100%;
    float: right;
    shape-outside: polygon(85.77% 5.24%, 100% 0px, 100% 100%, 39.19% 74.47%);
  }

  @media ${MEDIUM_BREAKPOINT} {
    shape-outside: polygon(85.77% 5.24%, 100% 0px, 100% 100%, 39.19% 74.47%);
  }

  .outer {
    position: relative;
    width: 100%;
    padding-bottom: ${(550 / 343) * 100}%;

    @media ${SMALL_BREAKPOINT} {
      padding-bottom: ${(439 / 1152) * 100}%;
    }
  }

  .inner {
    display: grid;
    position: absolute;
    width: 1098px;
    height: 762px;
    bottom: 0;
    left: 0;
    transform: translate(-318px, 234px);

    @media ${SMALL_BREAKPOINT} {
      bottom: auto;
      left: auto;
      right: 0;
      top: 0;
      transform: translate(388px, -176px);
    }

    @media ${MEDIUM_BREAKPOINT} {
      width: 1668px;
      height: 1400px;
      transform: translate(640px, -266px);
    }

    @media ${LARGE_BREAKPOINT} {
      transform: translate(654px, -382px);
    }
  }
`;

const IMAGE_CONFIG = {
  1: <DollarImage />,
  2: <TruckImage />,
  3: <ReturnsImage />,
};
const FeatureSection = ({ onSignup }) => {
  const featureContent = siteConfig["feature-section"];

  return (
    <FeatureSectionWrapper>
      <PageWrapper>
        <FeatureCardList>
          {featureContent.cards.map((item) => (
            <FeatureCard key={item.id}>
              {IMAGE_CONFIG[item.id]}
              <div>
                <h3>{item.title}</h3>
                <p>{item.content}</p>
              </div>
            </FeatureCard>
          ))}

          <CtaCard>
            <BgWrapper>
              <div className="outer">
                <div className="inner">
                  <StaticImage
                    style={{ gridArea: "1/1" }}
                    className="light-mode-only"
                    src="./images/feature-bg.png"
                    alt=""
                    layout="fullWidth"
                    // width="1668"
                    placeholder="tracedSVG"
                    breakpoints={[1600, 3200]}
                    quality={100}
                  />
                  <StaticImage
                    style={{ gridArea: "1/1" }}
                    className="dark-mode-only"
                    src="./images/feature-bg-dark.png"
                    alt=""
                    layout="fullWidth"
                    // width="1668"
                    placeholder="tracedSVG"
                    breakpoints={[1600, 3200]}
                    quality={100}
                  />
                </div>
              </div>
            </BgWrapper>

            <div className="content">
              <p>{featureContent.ctaCard}</p>
              <Button.Primary
                as="a"
                buttonSize="large"
                href={siteConfig.signUpUrl}
              >
                Get Started Today
              </Button.Primary>
            </div>
          </CtaCard>
        </FeatureCardList>
      </PageWrapper>
    </FeatureSectionWrapper>
  );
};

export default FeatureSection;
