import * as React from "react";
import HeroSection from "../components/index/hero-section";
import FeatureSection from "../components/index/feature-section";
import WorkWithUsSection from "../components/index/work-with-us-section";
import TestimonialsSection from "../components/index/testimonials-section";
import FaqSection from "../components/index/faq-section";
import BlogSection from "../components/index/blog-section";
import useSignup from "../components/index/signup";
import siteConfig from "../../site-config.json";
import PageLayout from "../layouts/page-layout";

const IndexPage = () => {
  const { startSignup } = useSignup();
  return (
    <PageLayout>
      <HeroSection onSignup={startSignup} />
      <FeatureSection onSignup={startSignup} />
      {siteConfig.features.blog && <BlogSection />}
      <TestimonialsSection />
      <FaqSection />
      {/* <WorkWithUsSection /> */}
    </PageLayout>
  );
};

export default IndexPage;
