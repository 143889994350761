import styled from "styled-components";
import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import PageWrapper from "../../page-wrapper";
import { SectionDivider } from "../../section-divider";
import Button from "../../button";

const MEDIUM_BREAKPOINT = "screen and (min-width: 800px)";
const LARGE_BREAKPOINT = "screen and (min-width: 1024px)";
const WorkWithUsPageWrapper = styled(PageWrapper)`
  padding-top: 51px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font: var(--text-font-h5);
  font-weight: 600;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;

  @media ${LARGE_BREAKPOINT} {
    padding-top: 6em;
    align-items: center;
    flex-direction: row;
    text-align: left;
  }

  ${SectionDivider} {
    position: relative;
    z-index: -2;
    margin-top: 72px;
    flex: 0 0 100%;
  }

  h2 {
    font: var(--text-font-h0);
    font-weight: 700;
    margin: 16px 0;

    @media ${LARGE_BREAKPOINT} {
      margin: 35px 0 40px;
    }

    strong {
      font-weight: inherit;
      display: block;
      color: var(--theme-color-primary);

      .dark-mode & {
        color: #fff2c9;
      }
    }
  }

  p {
    max-width: ${(287 / 375) * 100}%;
    opacity: 0.75;
    font: var(--text-font-body-medium);
    margin: 0 auto;

    @media ${LARGE_BREAKPOINT} {
      margin: 0;
      max-width: 44.142857em;
    }
  }

  .show-for-large {
    display: none;

    @media ${LARGE_BREAKPOINT} {
      display: flex;
    }
  }

  .hide-for-large {
    @media ${LARGE_BREAKPOINT} {
      display: none;
    }
  }

  .content {
    flex: 1;

    .dark-mode & a {
      color: #fff2c9;
    }
  }
`;

const ImageWrapper = styled.div`
  margin: auto;
  margin-top: 11px;
  max-width: 313px;

  @media ${MEDIUM_BREAKPOINT} {
    max-width: 100%;
  }

  @media ${LARGE_BREAKPOINT} {
    padding-right: ${(178 / 1440) * 100}%;
    order: -1;
    flex: 0 1 auto;
    width: ${((592 + 178) / 1440) * 100}%;
    margin: 0;
    margin-top: 0;
    margin-right: auto;
  }
`;

const EmailUsButton = styled(Button.Circle)`
  text-decoration: none;
  background-color: rgba(98, 52, 5, 0.05);
  border-color: rgba(98, 52, 5, 0.1);
  width: 101px;
  height: 101px;
  margin-top: 70px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(56px, -10px);

  &:hover,
  &:focus-visible {
    background-color: #623405;

    .dark-mode && {
      background-color: #623405;
    }
  }

  .dark-mode && {
    background-color: rgba(255, 242, 201, 0.05);
    box-shadow: 0 0 0 1px rgba(255, 242, 201, 0.1);
    color: #fff2c9;
  }

  @media ${MEDIUM_BREAKPOINT} {
    width: 136px;
    height: 136px;
  }

  @media ${LARGE_BREAKPOINT} {
    position: relative;
    left: 0;
    float: right;
    transform: none;
  }
`;

const CtaText = styled.p`
  && {
    font-size: 18px;
    font-weight: 500;
    opacity: 1;
    max-width: 100%;

    span {
      opacity: 0.5;
    }

    a {
      font-weight: 600;
      text-underline-offset: 12px;
      text-decoration-color: rgba(98, 52, 5, 0.5);

      .dark-mode & {
        text-decoration-color: rgba(255, 242, 201, 0.5);
        color: #fff2c9;
      }
    }
  }
`;

const WorkWithUsSection = () => {
  return (
    <section id="careers">
      <WorkWithUsPageWrapper>
        <div className="content">
          <CtaText>
            <span>Shoot your resumes at </span>
            <a href="mailto:people@procure.li">people@procure.li</a>
          </CtaText>
          <h2>
            Let's work
            <strong>Together</strong>
          </h2>
          <div>
            <EmailUsButton
              className="show-for-large"
              as="a"
              href="mailto:people@procure.li"
            >
              Email us
            </EmailUsButton>
            <p>
              If you love solving real-life problems and thrive in a fast-paced
              environment, then shoot your resume at{" "}
              <a href="mailto:people@procure.li">people@procure.li</a>. We are
              looking for billing and EMR, homecare, virtual assitant, digital
              marketing, and top-notch engineering candidates.
            </p>
          </div>
        </div>
        <ImageWrapper>
          <StaticImage
            className="image"
            src="./images/work-with-us-bg.png"
            width={414}
            layout="constrained"
            alt=""
            placeholder="tracedSVG"
          />
          <EmailUsButton
            className="hide-for-large"
            as="a"
            href="mailto:people@procure.li"
          >
            Email us
          </EmailUsButton>
        </ImageWrapper>
        <SectionDivider />
      </WorkWithUsPageWrapper>
    </section>
  );
};

export default WorkWithUsSection;
