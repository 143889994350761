import * as React from "react";
import styled from "styled-components";

import PageWrapper from "../../page-wrapper";
import { HeroLogos } from "./hero-logos";
import Button from "../../button";
import siteConfig from "../../../../site-config.json";

const HeroWrapper = styled.section`
  position: relative;
  height: 568px;
  margin-top: calc(-1 * var(--header-total-height));
  display: flex;
  text-align: center;

  @media screen and (min-width: 800px) {
    height: 100vh;
    min-height: 45em;
    max-height: 55em;
  }
`;

const TitleWrapper = styled.h2`
  margin: auto auto 0;
  font: var(--text-font-h1);
  line-height: 1.25em;
  font-weight: 700;
  text-transform: capitalize;

  @media screen and (min-width: 600px) {
    line-height: 98px;
    margin: auto auto 0;
  }

  em {
    color: var(--theme-color-primary);
    font-style: normal;

    .dark-mode & {
      color: #fff2c9;
    }
  }
`;

const CenterWrapper = styled(PageWrapper)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`;

const OverlineTextWrapper = styled.p`
  font: var(--text-font-body-large);
  font-weight: 500;
  opacity: 0.75;
  // max-width: 28.708333em;
  margin: 16px auto 32px;
`;

const SubtitleTextWrapper = styled.p`
  font: var(--text-font-body-large);
  font-weight: 500;
  opacity: 0.75;
  display: block;

  &.footer {
    position: absolute;
    bottom: 105px;
    opacity: 0.5;
    left: 0;
    right: 0;

    @media screen and (min-width: 800px) {
      bottom: 105px;
    }
  }
`;

const CtaWrapper = styled.div`
  margin-bottom: auto;

  * + * {
    margin-left: 16px;

    @media screen and (max-width: 400px) {
      margin-left: 8px;
    }
  }

  button {
    @media screen and (max-width: 400px) {
      padding: 0 10px;
    }
  }
`;

const HeroSection = ({ onSignup }) => {
  const heroContent = siteConfig["hero-section"];

  return (
    <HeroWrapper>
      <CenterWrapper>
        <TitleWrapper>
          <em>EMR, Medical Billing, & Virtual Assistants</em>
        </TitleWrapper>
        <OverlineTextWrapper>
          Let us help you reduce overhead, improve the collection, and <br />
          supercharge your healthcare practice.
        </OverlineTextWrapper>
        <CtaWrapper>
          <Button.Primary buttonSize="large" as="a" href={siteConfig.signUpUrl}>
            Get Started Today
          </Button.Primary>
          {/* <Button.Secondary
            buttonSize="large"
            onClick={() => onSignup("seller")}
          >
            Get Started Today
          </Button.Secondary> */}
        </CtaWrapper>
        <SubtitleTextWrapper className="footer">
          Some of the billing software we specialize in:
        </SubtitleTextWrapper>
        <HeroLogos />
      </CenterWrapper>
    </HeroWrapper>
  );
};

export default HeroSection;
