import styled from "styled-components";

const Card = styled.div`
  border-radius: 24px;
  font: var(--text-font-h4);
  padding: 16px;

  @media screen and (min-width: 800px) {
    padding: 1.333333em;
  }
`;

export default Card;
