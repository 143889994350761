import * as React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PageWrapper from "../../page-wrapper";
import SectionTitle from "../../section-title";
import { graphql, useStaticQuery } from "gatsby";

const MOBILE_BREAKPOINT = "screen and (min-width: 600px)";

const BlogSectionWrapper = styled.section`
  background: #fff;
  text-align: center;
  padding: 32px 0 48px;

  @media ${MOBILE_BREAKPOINT} {
    padding-top: 6.857143em;
    padding-bottom: 6.857143em;
  }

  .subtitle {
    opacity: 0.5;
    font-weight: 500;
    margin-bottom: 3.428571em;
  }
`;

const BlogGrid = styled.ul`
  list-style: none;
  text-align: left;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-rows: repeat(3, 96px);
  grid-row-gap: 12px;

  @media ${MOBILE_BREAKPOINT} {
    grid-column-gap: 1.142857em;
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const BlogCardWrapper = styled.li`
  margin: 0;
  display: grid;
  grid-template-columns: 112px 1fr;
  grid-template-rows: 100%;
  grid-column-gap: 16px;
  position: relative;

  @media ${MOBILE_BREAKPOINT} {
    display: block;
  }

  img {
    border-radius: 12px;
    object-fit: cover;

    @media ${MOBILE_BREAKPOINT} {
      border-radius: 16px;
    }
  }

  .details {
    align-self: center;

    @media ${MOBILE_BREAKPOINT} {
      padding: 0 1.714286em;
    }
  }

  .tag {
    font: var(--text-font-overline);
    font-weight: 700;
    letter-spacing: var(--text-overline-letter-spacing);
    color: var(--theme-color-primary);
    opacity: 0.5;
    text-transform: uppercase;
    margin-bottom: 6px;

    @media ${MOBILE_BREAKPOINT} {
      margin-top: 2em;
      margin-bottom: 1.333333em;
    }
  }

  h3 {
    font: var(--text-font-h3);
    font-weight: 700;
    margin: 0;

    @media ${MOBILE_BREAKPOINT} {
      margin-top: 1.333333em;
      margin-bottom: 2em;
    }
  }

  p {
    font: var(--text-font-body-medium);
    font-weight: 500;
    opacity: 0.5;
    display: none;

    @media ${MOBILE_BREAKPOINT} {
      display: block;
    }
  }

  a {
    font: var(--text-font-overline);
    letter-spacing: var(--text-overline-letter-spacing);
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;

    @media ${MOBILE_BREAKPOINT} {
      position: static;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`;

const BlogCard = ({ post }) => {
  const image = getImage(post.image);

  return (
    <BlogCardWrapper>
      <GatsbyImage image={image} alt="" />
      <div className="details">
        <div className="tag">
          {post.tag} - {post.publishDate}
        </div>
        <h3>{post.title}</h3>
        <p>{post.excerpt}</p>
        <a href="#">Read more</a>
      </div>
    </BlogCardWrapper>
  );
};

const BlogSection = () => {
  const data = useStaticQuery(graphql`
    query PostsQuery {
      posts: allPostsJson(limit: 3) {
        nodes {
          id
          excerpt
          publishDate(fromNow: true)
          tag
          title
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  return (
    <BlogSectionWrapper id="community">
      <PageWrapper>
        <SectionTitle>Community</SectionTitle>
        <p className="subtitle">
          Company news, blog posts, and everything else in-between.
        </p>
        <BlogGrid>
          {data.posts.nodes.map((post) => (
            <BlogCard post={post} key={post.id} />
          ))}
        </BlogGrid>
      </PageWrapper>
    </BlogSectionWrapper>
  );
};

export default BlogSection;
