import * as React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import PageWrapper from "../../page-wrapper";
import siteConfig from "../../../../site-config.json";

const MOBILE_BREAKPOINT = "screen and (min-width: 600px)";

const LogosWrapper = styled(PageWrapper)`
  position: absolute;
  bottom: 48px;
  left: 0;
  right: 0;
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  user-select: none;
  scrollbar-width: none;
`;

const OverlineTextWrapper = styled.p`
  font: var(--text-font-body-medium);
  font-weight: 500;
  opacity: 0.75;
  margin: 16px auto 32px;
`;

const InnerWrapper = styled.div`
  width: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  @media ${MOBILE_BREAKPOINT} {
    width: 1000px;
  }

  > * {
    flex: 0 0 auto;
    scroll-snap-align: center;
    pointer-events: none;
    padding: 1px;
  }
`;

export const HeroLogos = () => {
  if (!siteConfig.features["hero-logos"]) return null;

  return (
    <LogosWrapper>
      <InnerWrapper>
        <StaticImage
          className="light-mode-only"
          src="./images/advanced-md.png"
          placeholder="none"
          alt="Advanced MD"
          loading="eager"
          width={164}
          layout="fixed"
        />
        <StaticImage
          className="dark-mode-only"
          src="./images/advanced-md-dark.png"
          placeholder="none"
          alt="Advanced MD"
          loading="eager"
          width={164}
          layout="fixed"
        />

        <StaticImage
          className="light-mode-only"
          src="./images/eclinical-works.png"
          placeholder="none"
          alt="eClinical Works"
          loading="eager"
          width={164}
          layout="fixed"
        />
        <StaticImage
          className="dark-mode-only"
          src="./images/eclinical-works-dark.png"
          placeholder="none"
          alt="eClinical Works"
          loading="eager"
          width={164}
          layout="fixed"
        />

        <StaticImage
          className="light-mode-only"
          src="./images/office-ally.png"
          placeholder="none"
          alt="Office Ally"
          loading="eager"
          width={75}
          layout="fixed"
        />
        <StaticImage
          className="dark-mode-only"
          src="./images/office-ally-dark.png"
          placeholder="none"
          alt="Office Ally"
          loading="eager"
          width={75}
          layout="fixed"
        />

        <StaticImage
          className="light-mode-only"
          src="./images/collaborate-md.png"
          placeholder="none"
          alt="Collaborate MD"
          loading="eager"
          width={164}
          layout="fixed"
        />
        <StaticImage
          className="dark-mode-only"
          src="./images/collaborate-md-dark.png"
          placeholder="none"
          alt="Collaborate MD"
          loading="eager"
          width={164}
          layout="fixed"
        />

        <StaticImage
          className="light-mode-only"
          src="./images/practice-fusion.png"
          placeholder="none"
          alt="Practice Fusion"
          loading="eager"
          width={164}
          layout="fixed"
        />
        <StaticImage
          className="dark-mode-only"
          src="./images/practice-fusion-dark.png"
          placeholder="none"
          alt="Practice Fusion"
          loading="eager"
          width={164}
          layout="fixed"
        />

        <StaticImage
          className="light-mode-only"
          src="./images/kareo.png"
          placeholder="none"
          alt="Kareo"
          loading="eager"
          width={100}
          layout="fixed"
        />
        <StaticImage
          className="dark-mode-only"
          src="./images/kareo-dark.png"
          placeholder="none"
          alt="Kareo"
          loading="eager"
          width={100}
          layout="fixed"
        />

        <StaticImage
          className="light-mode-only"
          src="./images/therapy-notes.png"
          placeholder="none"
          alt="Therapy Notes"
          loading="eager"
          width={150}
          layout="fixed"
        />
        <StaticImage
          className="dark-mode-only"
          src="./images/therapy-notes-dark.png"
          placeholder="none"
          alt="Therapy Notes"
          loading="eager"
          width={150}
          layout="fixed"
        />
      </InnerWrapper>
    </LogosWrapper>
  );
};
